@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Nicolas Cochin';
  src: url(/static/media/Nicolas-Cochin-EF_Regular.5af711ad.ttf);
}

body {
  font-family: "Inter", sans-serif;
}

h2 {
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

header {
  color: #444;
}

header .title {
  font-family: "Nicolas Cochin";
  font-size: 5rem;
  letter-spacing: 1.15rem;
  background: white;
  padding: 0 50px;
}
header .subtitle {
  font-family: "Nicolas Cochin";
  font-size: 1.2rem;
  letter-spacing: 0.25rem;
}

.hero {
  font-size: 1.1rem;
  margin: 150px auto;
  max-width: 565px;
  line-height: 1.75em;
  /* flex: 1; */
  /* font-weight: 500; */
}

.logo {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* position: absolute; */
  /* top: 50px;
  left: 50% */
}

.ligne {
  width: 60%;
  border: solid 0.5px rgb(163, 163, 163);
  margin: 35px 0;
  /* margin-top: 1rem; */
}

@media all and (max-width: 800px) {
  header .title {
    font-size: 4rem;
    letter-spacing: 0.8rem;
  }
  header .subtitle {
    font-size: 1rem;
    letter-spacing: 0.2rem;
  }
  .hero {
    padding: 0 20px;
  }
}
@media all and (max-width: 632px) {
  header .title {
    font-size: 1.5rem;
    letter-spacing: 0.4rem;
  }
  header .subtitle {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  .hero {
    margin: 100px auto;
  }
  .ligne {
    margin: 20px 0;
  }
}

header ul.menu {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0px;
  margin: 0;
  background: white;
  width: 100%;
}

header ul.menu li {
  flex: 1 1;
  /* max-width: 200px; */
  min-width: 150px;
  font-size: 12px;
  margin: 0 10px;
}
header ul.menu li a {
  position: relative;
  display: block;
  padding-top: 10px;
  color: rgb(92, 92, 92);
  text-transform: uppercase;
  /* text-decoration: none; */
}
/* header ul.menu li a:hover :visited {
  text-decoration: none;
} */

/* header ul.menu li:hover ::after {
  transform: scaleX(1);
}

header ul.menu li ::after {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left center 0px;
  content: " ";
  background: rgb(0, 194, 154);
  background: rgb(92, 92, 92);
  transition: all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
} */

.flags {
  margin: 10px -10px 0 -10px;
}
.flags .flag-item {
  margin: 10px;
  height: 15px;
  border-radius: 2px;
  cursor: pointer;
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
}
.flags .flag-item:hover {
  outline-color: rgb(163, 163, 163);
}
.flags .flag-item.active {
  outline-color: skyblue;
}

.close-button {
  font-size: 0.7rem;
  position: absolute;
  top: -20px;
}
.close-button:hover {
  text-decoration: underline;
}
a:hover {
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  overflow: auto;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.Modal {
  max-width: 800px;
  /* min-height: 400px; */
  background: white;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  outline: none;
  padding: 50px;
  border: solid 1px gainsboro;
  border-radius: 4px;
  position: relative;
}

footer {
  font-family: "Nicolas Cochin";
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  width: 100%;
}

.warning,
.places {
  letter-spacing: 0.15rem;
  -webkit-animation: blink-animation 8s 4s infinite;
          animation: blink-animation 8s 4s infinite;
  opacity: 0;
}
.places {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@-webkit-keyframes blink-animation {
  7.5% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  42.5% {
    opacity: 0;
  }
}
@keyframes blink-animation {
  7.5% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  42.5% {
    opacity: 0;
  }
}

