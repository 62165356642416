body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Nicolas Cochin';
  src: url(./Nicolas-Cochin-EF_Regular.ttf);
}
